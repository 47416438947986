import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/styles/index.css";
import { authState } from "./store/auth";

const app = createApp(App)
app.use(router)

authState.init();
app.mount("#app");

