import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import authRoutes from "@/views/auth/auth.routes";
import heroRoutes from "@/views/hero/hero.routes";
import aboutRoutes from "@/views/about/about.routes";
import overviewRoutes from "@/views/overview/overview.routes";
import visionRoutes from "@/views/vision/vision.routes";
import goalsRoutes from "@/views/goals/goals.routes";
import servicesRoutes from "@/views/services/services.routes";
import specificationRoutes from "@/views/specification/specification.routes";
import commitmentRoutes from "@/views/commitment/commitment.routes";
import contactRoutes from "@/views/contact/contact.routes";
import MainLayout from "@/components/MainLayout.vue"
import { authState } from "@/store/auth"

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "mainLayout",
    component: MainLayout,
    redirect: '/hero',
    children: [
      {
        path: "hero",
        children: heroRoutes
      },
      {
        path: "about",
        children: aboutRoutes
      },
      {
        path: "overview",
        children: overviewRoutes
      },
      {
        path: "vision",
        children: visionRoutes
      },
      {
        path: "goals",
        children: goalsRoutes
      },
      {
        path: "services",
        children: servicesRoutes
      },
      {
        path: "specification",
        children: specificationRoutes
      },
      {
        path: "commitment",
        children: commitmentRoutes
      },
      {
        path: "contact",
        children: contactRoutes
      }
    ]
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/components/notFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...authRoutes],
});

router.beforeEach((to) => {
  if (to.name !== 'login' && !authState.auth) {
    return { name: "login" }
  }

  if (to.name == 'login' && authState.auth) {
    return '/'
  }
})

export default router;
