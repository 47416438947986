<template>
  <div
    class="fixed xl:sticky xl:left-0 top-0 bg-white h-screen shadow-[0_0_8px_1px_#969696] px-2 xl:py-2 xl:mt-0 screen:overflow-y-auto z-50 transition-all duration-300 w-72 xl:w-64"
    :class="isSideBarOpen ? '-left-72' : 'left-0'"
  >
    <div class="flex items-center justify-between gap-6 md:gap-8 cursor-pointer py-1 pr-2 ">
      <div class="xl:hidden">
        <BurgerMenu />
      </div>
      <img
        class="w-52 xl:w-56"
        src="@/assets/images/logo.png"
      >
    </div>
    <SideBarItem :side-bar="sideBar" />

    <div
      class="flex items-center gap-5 cursor-pointer hover:bg-yellow-100 py-1 mt-8 rounded-md"
      @click="logout()"
    >
      <svg
        viewBox="0 0 24 24"
        class="h-8 w-8 fill-current mr-2 "
      >
        <path
          fill="currentColor"
          :d="mdiLogout"
        />
      </svg>
      <span>logout</span>
    </div>
  </div>
</template>

<script setup lang="ts">

import {
  mdiLogout,
  mdiFolderOpenOutline,
  mdiCardAccountPhoneOutline,
  mdiRoomServiceOutline,
  mdiToolboxOutline,
  mdiClipboardOutline,
  mdiCookie,
  mdiDipSwitch,
  mdiDnsOutline,
  mdiFileDocumentOutline

} from "@mdi/js";
 import SideBarItem from "./SideBarItem.vue";
import { isSideBarOpen } from "@/store/sideBar";
import BurgerMenu from "./svgs/burgerMenu.vue"
import { logout } from "@/views/auth/auth.service"

const sideBar = [
  { title: "Hero", icon: mdiClipboardOutline, route: "/hero" },
  { title: "About", icon: mdiFileDocumentOutline, route: "/about" },
  { title: "Overview", icon: mdiCookie, route: "/overview" },
  { title: "Vision", icon: mdiDipSwitch, route: "/vision" },
  { title: "Goals", icon: mdiFolderOpenOutline, route: "/goals" },
  { title: "Services", icon: mdiRoomServiceOutline, route: "/services" },
  { title: "Specifications", icon: mdiDnsOutline, route: "/specification" },
  { title: "Commitment", icon: mdiToolboxOutline, route: "/commitment" },
  { title: "Contact", icon: mdiCardAccountPhoneOutline, route: "/contact" },
]


</script>
