import { RouteRecordRaw } from 'vue-router'

import Login from '@/views/auth/pages/login-auth.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/login',
        name: 'login',
        component: Login
    }
]

export default routes