import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'vision',
        component: () => import("./pages/vision-edit.vue")
    },
]

export default routes