import { auth } from "@/views/auth/models/auth";
import { reactive } from "vue";

const authState = reactive<{
    auth: auth | null
    set: (auth: auth) => void
    init: () => void,
    clear: () => void
}>({
    init() {
        const auth = localStorage.getItem('auth');
        if (auth) {
            this.auth = JSON.parse(auth);
        }
    },
    auth: null,
    set(authResponse: auth) {
        localStorage.setItem('auth', JSON.stringify(authResponse));
        this.auth = authResponse;
    },
    clear() {
        this.auth = null;
        localStorage.removeItem("auth");
    },
})

export { authState };