import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'about',
        component: () => import("./pages/about-edit.vue")
    }
]

export default routes