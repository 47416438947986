<template>
  <div class="grid lg:grid-cols-2 h-screen px-6 max-w-[1440px] mx-auto">
    <div class="w-full sm:w-3/4 mx-auto flex flex-col justify-center  ">
      <p class="text-3xl md:text-5xl font-semibold text-center">
        Welcome Back
      </p>
      <p class="mt-4 text-gray-800 text-center">
        Welcome back , enter your data to login
      </p>

      <form class="mt-8">
        <div>
          <LabelItem
            for="userName"
            name="User Name"
          />

          <input
            id="userName"
            v-model.trim="userName"
            name="address"
            type="text"
            class="mt-2 w-full border border-gray-300 px-2 py-3 rounded-lg outline-none  focus:ring-2 focus:ring-primary-100 transition-all duration-100"
            placeholder="User Name"
          >

          <p
            :class="errors.userName ? 'opacity-100 pointer-events-auto translate-y-0' : 'opacity-0 pointer-events-none -translate-y-4'"
            class="mt-1 pr-2 text-sm text-red-500 transition-all duration-200  "
          >
            {{ errors.userName }}
          </p>
        </div>
        <div class="mt-4">
          <LabelItem
            for="password"
            name="Password"
          />

          <input
            id="password"
            v-model.trim="password"
            type="text"
            class="mt-2 w-full border border-gray-300 px-2 py-3 rounded-lg outline-none  focus:ring-2 focus:ring-primary-100 transition-all duration-100"
            placeholder="Password"
          >

          <p
            :class="errors.password ? 'opacity-100 pointer-events-auto translate-y-0' : 'opacity-0 pointer-events-none -translate-y-4'"
            class="mt-1 pr-2 text-sm text-red-500 transition-all duration-200  "
          >
            {{ errors.password }}
          </p>
        </div>

        <button
          :disabled="isDisabled"
          :class="isDisabled ? 'opacity-70 cursor-default' : 'cursor-pointer '"
          type="submit"
          class="flex justify-center items-center gap-2 w-full h-12 mt-4 bg-[#dcbe48] transition-all duration-150 
                text-white px-8  rounded-md  "
          @click="login"
        >
          <span>Login </span>
          <SpinAnimation
            :is-loading="isLoading"
            custom-style="animate-spin h-8 w-8 text-white"
          />
        </button>
      </form>
    </div>

    <div class="hidden lg:grid place-content-center ">
      <img
        src="../../../assets/images/logo.png"
        alt=""
        class="w-[30rem] xl:w-[35rem]"
      >
    </div>
  </div>
</template>
<script setup lang="ts">

import SpinAnimation from '@/components/svgs/spinAnimation.vue';
import { computed, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import LabelItem from '@/components/labelItem.vue';
import router from '@/router';
import { authState } from "@/store/auth"

const isLoading = ref(false)
const isDisabled = computed(() => {
  return isLoading.value || !meta.value.valid
})

const validationSchema = toTypedSchema(
  zod.object({
    userName: zod.string().min(1, "Username is required"),
    password: zod.string().min(1, "password is required"),
  })
)

const { handleSubmit, errors, meta } = useForm({
  validationSchema,
})

let { value: userName } = useField('userName')
let { value: password } = useField('password')

const authenticatedUser = {
  username: 'admin',
  password: 'Aa@12345',
}

const login = handleSubmit(values => {
  isLoading.value = true

  if (authenticatedUser.username == values.userName && authenticatedUser.password == values.password) {
    authState.set(authenticatedUser)
    router.replace("/")
  } else {
    errors.value.userName = "Username or password is not correct"
  }
  isLoading.value = false
})

</script>
