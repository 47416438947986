import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'hero',
        component: () => import("./pages/hero-edit.vue")
    },
]

export default routes