import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'overview',
        component: () => import("./pages/overview-edit.vue")
    },
]

export default routes